import AgendaEnum from '../enumerators/agenda.enum'

export default class AgendaComponent {
    constructor() {
        AgendaComponent.showAgenda()
    }

    static showAgenda() {
        $(AgendaEnum.TRIGGER_AGENDA).on('click', () => {
            $(AgendaEnum.CUSTOMER_AGENDA).toggleClass('is-active')
            $(AgendaEnum.BODY).toggleClass('agenda-open')

            //Hide ecomode
            $(AgendaEnum.ECOMODE).addClass('eco-bar--hidden')
        })
    }
}
