import NavbarEnum from '../enumerators/navbar.enum'

export default class NavbarComponent {
    constructor() {
        NavbarComponent.sticky()
        NavbarComponent.burger()
        NavbarComponent.langs_switcher()
        NavbarComponent.menu_switcher_subtitle()
        NavbarComponent.linkHover()
    }

    static sticky() {
        let lastScrollTop = 0
        let stateOnTop = true

        $(window).on('load scroll', function () {
            const offset = 150 // Navbar default height
            const offsetOnScroll = 100 // Navbar height on scroll
            const navbar = $(NavbarEnum.NAVBAR)
            const scrollTop = $(this).scrollTop()
            let hasBanner = false

            // Detect if header is on banner/video mode
            if ($('body.has-banner').length > 0) {
                hasBanner = true
            }

            // Hidden on scroll comportment
            if (scrollTop >= lastScrollTop && scrollTop > navbar.outerHeight()) {
                $(NavbarEnum.BODY).addClass('header-is-hidden')
            } else {
                $(NavbarEnum.BODY).removeClass('header-is-hidden')
            }

            lastScrollTop = scrollTop

            // On top/on scroll switch
            if (scrollTop > offset && stateOnTop === true) {
                stateOnTop = false
                navbar.removeClass('navbar--ontop')
                navbar.addClass('navbar--onscroll')
                $(NavbarEnum.BODY).removeClass('is-ontop')
                $(NavbarEnum.BODY).addClass('is-onscroll')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('classic')
                }
            } else if (scrollTop < offsetOnScroll && stateOnTop === false) {
                stateOnTop = true
                navbar.removeClass('navbar--onscroll')
                navbar.addClass('navbar--ontop')
                $(NavbarEnum.BODY).removeClass('is-onscroll')
                $(NavbarEnum.BODY).addClass('is-ontop')

                if (hasBanner === true) {
                    NavbarComponent.changeLogoColor('banner')
                }
            }
        })
    }

    static changeLogoColor(color) {
        const logo = $(NavbarEnum.LOGO)
        let logoBanner
        let logoClassic

        logoBanner = IRISCollectionCustomer.imageLogoBanner
        logoClassic = IRISCollectionCustomer.imageLogo

        if (color === 'banner') {
            logo.attr('src', logoBanner)
        } else if (color === 'classic') {
            logo.attr('src', logoClassic)
        }
    }

    static burger() {
        //Open/Close menu
        $(NavbarEnum.BURGER_MENU_TRIGGER).on('click', () => {
            $(NavbarEnum.BODY).toggleClass('menu-open')

            //Change logo on mobile
            if (window.matchMedia('(max-width: 650px)').matches) {
                if ($(NavbarEnum.BODY).hasClass('menu-open')) {
                    NavbarComponent.changeLogoColor('banner')
                } else {
                    if ($('.navbar--ontop').length > 0 && $('body.has-banner').length > 0) {
                        NavbarComponent.changeLogoColor('banner')
                    } else {
                        NavbarComponent.changeLogoColor('classic')
                    }
                }
            }

            //Hide ecomode
            $(NavbarEnum.ECOMODE).addClass('eco-bar--hidden')
        })

        //Open/Close submenu
        $(NavbarEnum.BURGER_MENU_MORE).on('click', function () {
            $(this).next(NavbarEnum.BURGER_SUBMENU).toggleClass('submenu-open')
            $(NavbarEnum.BODY).addClass('submenu-open')
        })

        $(NavbarEnum.BURGER_MENU_PREV).on('click', (e) => {
            e.preventDefault()
            $(NavbarEnum.BURGER_SUBMENU).removeClass('submenu-open')
            $(NavbarEnum.BODY).removeClass('submenu-open')
        })
    }

    static langs_switcher() {
        const current_lang = $('.lang__current')
        const list_langs = $('.lang__list')

        const current_lang_value = current_lang.first().text()

        //Hide submenu
        list_langs.hide()

        //Open submenu
        $(NavbarEnum.SWITCHER_LANG_TRIGGER).on('click', () => {
            $(NavbarEnum.LIST_LANG).toggleClass('lang__list--open')
            $('.lang__list--open').fadeIn(250)
            $('.lang__list:not(.lang__list--open)').fadeOut(250)

            //Hide list lang on outside click
            $(window).click((e) => {
                if (
                    e.target.className !==
                        'langSwitcher_current fi fi--after fi--customer-arrow-down lang__current notranslate' &&
                    e.target.className !== 'current__lang'
                ) {
                    if ($(NavbarEnum.LIST_LANG).hasClass('lang__list--open')) {
                        $(NavbarEnum.LIST_LANG).removeClass('lang__list--open')
                        list_langs.fadeOut(250)
                    }
                }
            })
        })

        //Remove current language on list (init)
        $('.lang__list > a').each(function () {
            if ($(this).text() === current_lang_value) {
                $(this).hide()
            }
        })

        //Change Navbar Language on click
        $('.lang__list > a').on('click', function () {
            $('.lang__list > a').show()
            $(this).hide()
            $(current_lang).text($(this).text())
        })
    }

    static menu_switcher_subtitle() {
        $(NavbarEnum.MENU_LIST_LINK_TRIGGER).first().addClass('is-active')
        $(NavbarEnum.LIST_ITEM_MENU).first().addClass('is-active')

        if (window.matchMedia('(min-width:1000px)').matches) {
            $(NavbarEnum.MENU_LIST_LINK_TRIGGER).on('click', function (e) {
                //Check if submenu is open
                if ($(this).hasClass('is-active')) {
                    return true
                }

                //first click
                e.preventDefault()
                $(NavbarEnum.MENU_LIST_LINK_TRIGGER).removeClass('is-active')
                $(NavbarEnum.LIST_ITEM_MENU).removeClass('is-active')
                $(this).addClass('is-active')
                $(this).next(NavbarEnum.LIST_ITEM_MENU).addClass('is-active')
            })
        }
    }

    static linkHover() {
        if (window.matchMedia('(min-width: 1001px)').matches) {
            $(NavbarEnum.MENU_ITEM).hover(
                function () {
                    //Display animation
                    $(NavbarEnum.NAVBAR).addClass('active')
                    $(this).find(NavbarEnum.MENU_ITEM_LINK).addClass('active-link')
                },
                function () {
                    $(NavbarEnum.NAVBAR).removeClass('active')
                    $(this).find(NavbarEnum.MENU_ITEM_LINK).removeClass('active-link')
                },
            )
        }
    }
}
