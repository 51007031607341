/*
 * Created by IRIS Interactive
 * User : IRIS Interactive
 */

import HeaderService from './services/header.service'
import LayoutService from './services/layout.service'

document.addEventListener('DOMContentLoaded', () => {
    new HeaderService()
    new LayoutService()

    wp.hooks.addFilter('wp-etourisme.override-lang-os', 'App', (codeLang) => {
        if (typeof gtranslateSettings !== 'undefined') {
            const htmlLang = document.querySelector('html').getAttribute('lang')
            if (htmlLang === 'en' || htmlLang === 'de') {
                return 'uk'
            }
            if (htmlLang === 'es') {
                return htmlLang
            }
        }
        return codeLang
    })
})
