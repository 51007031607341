export default class NavbarEnum {
    static BODY = 'body'
    static NAVBAR = '[data-element="navbar"]'
    static LOGO = '[data-element="navbar-logo"]'
    static ECOMODE = '[data-id="eco-bar"]'
    static BURGER_MENU_TRIGGER = '[data-trigger="burger-menu-toggle"]'
    static BURGER_MENU_MORE = '[data-trigger="burger-menu-more"]'
    static BURGER_MENU_PREV = '[data-trigger="burger-menu-prev"]'
    static BURGER_SUBMENU = '[data-element="burger-submenu"]'
    static SWITCHER_LANG_TRIGGER = '[data-trigger="switcher-lang"]'
    static LIST_LANG = '[data-id="list-lang"]'
    static MENU_LIST_LINK_TRIGGER = '[data-trigger="menu-list-link"]'
    static LIST_ITEM_MENU = '[data-id="list-item-menu"]'
    static MENU_ITEM = '[data-element="menu-item"]'
    static MENU_ITEM_LINK = '[data-element="menu-item-link"]'
}
